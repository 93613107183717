/* Scss Document */

/* font-family */

$p_family_1_1: "moanhandregular",
serif; //titres gras toutes pages
$p_family_1_2: "poppinsregular",
serif; //lien boutton, lien actus, titres toutes pages, titre slider
$p_family_2_1: "poppinslight",
sans-serif; //texte courant
$p_family_2_2: "poppinsregular",
sans-serif; //texte courant
$p_family_2_3: "poppinsbold",
sans-serif; //texte courant
$p_strong:"poppinsbold",
sans-serif;
//$p_family_2_4: "roboto_condensed", sans-serif; //
//$p_family_2_5: "roboto_condensed", sans-serif; //
/* couleurs */

$p_base_byresta: #222326; //bg page
$p_white: #FFFFFF; //texte slider, lien footer, liens navbar, texte bouttons
$p_marron: #b2677d; //titres, survol lien
$p_blancasse: #EAEAEA; //fond liste actus
$p_gristresclair: #B7B7B7; //texte
$p_grisclair: #808080; //texte
$p_grismoyen: #666666; //texte, lien navbar
$p_grisfonce: #4D4D4D; //texte
$p_orange: #b31b34; //survol lien
$p_bleufonce: #222326; //fond div, survol lien, titres
$p_bleuclair: #404247; //fond sous-footer
$p_bg_footer: #222326; //bg_footer
$p_black: #000000; //bg_footer
/* font-size */

$p_titre_headder: 20px; //titre accueil
$p_titre_headder_petit: 16px; //titre headder accueil
$p_titre_lien_1: 24px; //titre lien
$p_titre_lien_2: 20px; //titre lien
$p_titre_lien_3: 16px; //titre lien
$p_titre_h1: 60px; //titres accueil
$p_titre_h2: 40px; //titres pages
$p_titre_h3: 34px; //titres pages
$p_titre_h4: 24px; //titres pages
$p_soustitre_h2: 42px; //sous titre
$p_intertitre_h2: 20px; //paragraphe accroche presentation
$p_texte_base: 16px; //texte courant
$p_texte_petit: 14px; //texte petit
$p_lien_nav: 16px; // lien navbar
$p_texte_newsletter: 22px; //texte cartouche
$p_titre_actus_accueil: 26px; //texte cartouche
$p_titre_actus_accueil_resp: 18px; //texte cartouche
$p_h2_actus: 25px; //titre actus et details actus
$p_h2_actus_resp: 18px; //titre actus et details actus
$p_date_actus: 16px; //titre actus et details actus
$p_texte_footer: 16px; // texte footer
$p_titre_footer: 24px; // titre footer
$p_texte_sousfooter: 12px; // texte et lien footer02

/* images de background */

$p_texture_rouille: url(../images/texture_rouille.jpg); //fond container fluid
$p_texture_beton: url(../images/texture_beton.jpg); //fond container fluid
$p_texture_beton_clair: url(../images/texture_beton_clair.jpg); //fond bloc texte
$p_texture_ardoise: url(../images/texture_ardoise.jpg); //fond container fluid menu
$p_texture_points: url(../images/texture_points.png); //fond container fluid, actus
$p_texture_points_clair: url(../images/texture_points_clair.png); //fond detail actu
$p_texture_fonce_survol: url(../images/headder_trame.png); //survol
$p_texture_claire_survol: url(../images/texture_pointclair_survol.png); //survol
$p_newsletter: url(../images/back_newsletter.jpg); //fond newsletter
$p_filet-menu: url(../images/separe_nav.jpg); //fond newsletter
$p_fond-menu: url(../images/fond_menu.jpg); //fond newslette
$p_double-filet: url(../images/filet_bloc.png); //fond newsletter


/* break media queries */

$media_1200_1680: "screen and (min-width:1200px) and (max-width:1680px)";
$media_1200: "screen and (min-width:1200px)";
$media_992_1199: "screen and (min-width:992px) and (max-width:1199px)";
$media_768_991: "screen and (min-width:768px) and (max-width:991px)";
$media_480_767: "screen and (min-width:480px) and (max-width:767px)";
$media_320_479: "screen and (min-width:320px) and (max-width:479px)";
$media_0_991: "screen and (max-width:991px)";
$media_0_767: "screen and (max-width:767px)";
$media_0_500: "screen and (max-width:500px)";
$media_0_359: "screen and (max-width:359px)";
$media_0_319: "screen and (max-width:319px)";




/* coins-arrondis */

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}


@mixin border-radius-top($radius) {
	-webkit-border-top-right-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	-moz-border-radius-topright: $radius;
	-moz-border-radius-topleft: $radius;
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-radius-bottom($radius) {
	-webkit-border-bottom-right-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-bottomright: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-radius-sous-menu($radius) {
	-webkit-border-bottom-right-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-bottomright: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}


@mixin display-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin flex-direction-row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

@mixin flex-direction-column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

@mixin justify-content($justification) {
	-webkit-box-pack: $justification;
	-webkit-justify-content: $justification;
	-ms-flex-pack: $justification;
	justify-content: $justification;
}

@mixin align-items($align) {
	-webkit-box-align: $align;
	-webkit-align-items: $align;
	-ms-flex-align: $align;
	align-items: $align;
}

@mixin gradient-red {
	background: #6d0025;
	/* Old browsers */
	background: -moz-linear-gradient(left, #6d0025 0%, #ac1a2e 14%, #ac1a2e 50%, #ac1a2e 86%, #6d0025 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, #6d0025 0%, #ac1a2e 14%, #ac1a2e 50%, #ac1a2e 86%, #6d0025 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #6d0025 0%, #ac1a2e 14%, #ac1a2e 50%, #ac1a2e 86%, #6d0025 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d0025', endColorstr='#6d0025', GradientType=1);
	/* IE6-9 */
}