$icon-font-path: "../fonts/";
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "_variables.scss";
@import "_fonts.scss";
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

* {
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
}

body {
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
  background-color: $p_white;
  font-family: $p_family_2_1;
}

@media #{$media_0_767} {
  body {
    margin-top: 0px;
  }
}

a .active {
  color: $p_white;
}

/* =================== texte  =================== */

h1 {
  font-family: $p_family_1_2;
  font-size: $p_titre_h1;
  font-weight: normal;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

h2 {
  font-family: $p_family_1_2;
  font-size: $p_titre_h2;
  font-weight: normal;
  display: block;
  margin-bottom: 15px;
}

h3 {
  font-family: $p_family_1_2;
  font-size: $p_titre_h3;
  font-weight: normal;
  display: block;
  text-align: left;
  margin-bottom: 15px;
}

h4 {
  font-family: $p_family_1_2;
  font-size: $p_titre_h4;
  font-weight: normal;
  display: block;
  color: $p_marron;
  margin-bottom: 15px;
}

p {
  font-family: $p_family_2_1;
  font-size: $p_texte_base;
  font-weight: normal;
}

strong {
  font-weight: $p_strong;
}

.titre {
  font-family: $p_family_1_2;
  font-size: $p_titre_h2;
  color: $p_marron;
  font-weight: normal;
  display: block;
  background-color: $p_white;
  text-transform: uppercase;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  z-index: 10;
}

.sous-titre {
  color: $p_marron;
  font-family: $p_family_1_2;
  font-size: $p_intertitre_h2;
}

/* =================== NAVBAR =================== */

.navbar-fixed-top {
  z-index: 999;
  position: absolute;
  margin: 45px 0px 0px 0px;
  border-top: solid 1px $p_gristresclair;
  border-bottom: solid 1px $p_gristresclair;
  text-align: center;
  font-family: $p_family_2_2;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
  ul.navbar-nav {
    text-align: center;
    float: none;
    -webkit-transition: all 0.9s ease;
    transition: all 0.9s ease;
    li {
      display: inline-block;
      float: none;
      a {
        color: $p_gristresclair;
        font-size: $p_lien_nav;
        padding: 0px 30px 0px 30px;
        line-height: 48px;
      }
      a:hover,
      a:focus {
        display: block;
        background-color: $p_bleufonce;
        color: $p_white;
      }
      &:hover > ul.sous-menu {
        display: block;
      }
      ul.sous-menu {
        display: none;
        position: absolute;
        top: 46px;
        left: 0px;
        z-index: 1000;
        min-width: 170px;
        padding: 0px 0;
        margin: 2px 0 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: rgba(34, 35, 38, 0.7);
        background: rgba(34, 35, 38, 0.7);
        @include border-radius-sous-menu(4px);
        li {
          display: block;
          a {
            display: block;
            text-decoration: none;
            padding: 10px 0px 10px 0px;
            text-align: center;
            clear: both;
            font-weight: normal;
            line-height: 1.428571429;
            color: $p_gristresclair;
            &:hover,
            &.active {
              background-color: $p_bleufonce;
              color: $p_white;
            }
          }
        }
      }
    }
  }
}

.page {
  margin: 217px 0px 0px 0px;
}

.on {
  margin: 0px 0px 0px 0px;
  background-color: rgba(34, 35, 38, 0.8);
  background: rgba(34, 35, 38, 0.8);
}

.navbar-fixed-top .navbar-nav > .active > a,
.navbar-fixed-top .navbar-nav > .active > a:focus,
.navbar-fixed-top .navbar-nav > .active > a:hover {
  color: $p_white;
  background-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 15px 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: $p_orange;
  background-image: none;
  border: 1px solid $p_white;
  border-radius: 4px;
}

.icon-bar {
  background-color: $p_white;
}

.fond-menu {
  background-image: $p_fond-menu;
  background-position: center top;
  background-attachment: fixed;
  height: 307px;
  margin-bottom: 60px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 217px;
    width: 100%;
    .logo-page {
      z-index: 9;
      width: 400px;
    }
  }
}

.no-margin {
  margin-bottom: 0px;
}

@media #{$media_992_1199} {
  .navbar-fixed-top {
    margin: -1px 0px 0px 0px;
  }
  .navbar-fixed-top ul.navbar-nav li a {
    color: $p_gristresclair;
    font-size: $p_texte_petit;
    padding: 0px 7px 0px 7px;
    line-height: 48px;
  }
  .logo-page {
    width: 460px;
    //		top: 100px;
    top: 70px;
    left: 50%;
    //		margin-left: -230px;
  }
  .fond-menu {
    height: 260px;
    margin-bottom: 0px;
  }
}

@media #{$media_768_991} {
  .navbar-fixed-top {
    margin: -1px 0px 0px 0px;
  }
  .navbar-fixed-top ul.navbar-nav li a {
    color: $p_gristresclair;
    font-size: $p_texte_petit;
    padding: 0px 7px 0px 7px;
    line-height: 48px;
  }
  .logo-page {
    width: 480px;
    //		top: 100px;
    top: 70px;
    left: 50%;
    //		margin-left: -240px;
  }
  .fond-menu {
    height: 260px;
    margin-bottom: 0px;
  }
}

@media #{$media_0_767} {
  .navbar-fixed-top {
    margin: 20px 0px 0px 0px;
    background-color: rgba(25, 25, 25, 0.8);
    background: rgba(25, 25, 25, 0.8);
    ul.navbar-nav {
      li {
        display: block;
        float: none;
        a {
          color: $p_gristresclair;
          font-size: $p_lien_nav;
          padding: 0px 30px 0px 30px;
          line-height: 48px;
        }
        &:focus > ul.sous-menu {
          display: block;
        }
        ul.sous-menu {
          display: none;
          position: static;
          top: 46px;
          left: 0px;
          z-index: 1000;
          min-width: 170px;
          padding: 0px 0;
          margin: 2px 0 0;
          list-style: none;
          font-size: 14px;
          background-color: rgba(255, 255, 255, 0.8);
          background: rgba(255, 255, 255, 0.8);
          @include border-radius-sous-menu(4px);
          li {
            display: block;
            a {
              display: block;
              text-decoration: none;
              padding: 10px 0px 10px 0px;
              text-align: center;
              clear: both;
              font-weight: normal;
              line-height: 1.428571429;
              color: $p_bleufonce;
              &:hover {
                background-color: $p_bleufonce;
                color: $p_gristresclair;
              }
            }
          }
        }
      }
    }
  }
  .logo-page {
    width: 290px;
    top: 120px;
    left: 50%;
    //		margin-left: -145px;
  }
  .fond-menu {
    height: 240px;
    margin-bottom: 0px;
  }
}

@media #{$media_0_319} {
  .navbar-fixed-top {
    margin: 20px 0px 0px 0px;
    background-color: rgba(25, 25, 25, 0.8);
    background: rgba(25, 25, 25, 0.8);
    ul.navbar-nav {
      li {
        display: block;
        float: none;
        a {
          color: $p_gristresclair;
          font-size: $p_lien_nav;
          padding: 0px 30px 0px 30px;
          line-height: 48px;
        }
        a:hover,
        a:focus {
          display: block;
          background-color: $p_bleufonce;
          color: $p_white;
        }
        &:hover > ul.sous-menu {
          display: block;
        }
        ul.sous-menu {
          display: none;
          position: static;
          top: 46px;
          left: 0px;
          z-index: 1000;
          min-width: 170px;
          padding: 0px 0;
          margin: 2px 0 0;
          list-style: none;
          font-size: 14px;
          text-align: left;
          background-color: rgba(255, 255, 255, 0.8);
          background: rgba(255, 255, 255, 0.8);
          @include border-radius-sous-menu(4px);
          li {
            display: block;
            a {
              display: block;
              text-decoration: none;
              padding: 10px 0px 10px 0px;
              text-align: center;
              clear: both;
              font-weight: normal;
              line-height: 1.428571429;
              color: $p_bleufonce;
              &:hover,
              &.active {
                background-color: $p_bleufonce;
                color: $p_white;
              }
            }
          }
        }
      }
    }
  }
  .logo-page {
    width: 220px;
    top: 120px;
    left: 50%;
    //		margin-left: -110px;
  }
  .fond-menu {
    height: 220px;
    margin-bottom: 0px;
  }
}

/* =================== Headder Slider =================== */

.flexslider {
  margin: 0;
  min-height: 350px;
  border: 0px solid $p_white;
  position: relative;
  zoom: 0;
  border: 0px solid $p_white;
  .slides {
    li {
      position: relative;
      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 80px;
        @include display-flex;
        @include flex-direction-column;
        @include justify-content(center);
        @include align-items(center);
        .logo {
          text-align: center;
          width: 100%;
          z-index: 9;
          margin-bottom: 30px;
          .imagelogo {
            width: 600px;
            margin: auto;
          }
          .imagecarre {
            width: 7%;
            margin: auto;
          }
        }
        .titres {
          width: 60%;
          text-align: center;
          @include border-radius(6px);
          /*border: $p_grismoyen solid 1px;*/
          p {
            margin-bottom: 25px;
            margin-top: 20px;
            font-family: $p_family_1_2;
            font-size: $p_titre_headder;
            text-transform: uppercase;
            color: $p_white;
          }
          .btn-slide {
            display: inline-block;
            color: $p_marron;
            padding-bottom: 15px;
            width: 125px;
            margin: 0 auto;
            background: none;
            background-image: none;
            border: 1px solid $p_marron;
            white-space: nowrap;
            padding: 6px 12px;
            margin-bottom: 10px;
            font-family: $p_family_1_2;
            font-size: $p_titre_lien_2;
            text-transform: uppercase;
            line-height: 1.428571429;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:hover {
              background-image: none;
              background-color: $p_bleufonce;
              color: $p_orange;
            }
          }
        }
      }
    }
  }
}

.blend {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: $p_texture_fonce_survol;
  background-repeat: repeat;
}

.flexactus {
  margin: 80px 0px 10px 0px;
  background: $p_texture_points;
  border: 0px solid $p_white;
  position: relative;
  padding-bottom: 80px;
  padding-top: 40px;
  zoom: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  .slides {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

@media #{$media_992_1199} {
  .flexslider {
    .logo {
      width: 480px;
      .imagelogo {
        width: 100%;
      }
      .imagecarre {
        display: none;
      }
    }
    .slides {
      li {
        position: relative;
        .content {
          padding-top: 30px;
          .titres {
            width: 60%;
            padding: 10px;
            p {
              margin-bottom: 30px;
              margin-top: 30px;
              font-family: $p_family_1_2;
              font-size: $p_titre_headder-petit;
              text-transform: uppercase;
              color: $p_white;
            }
            .btn-slide {
              font-size: $p_titre_lien_3;
            }
          }
        }
      }
    }
  }
}

@media #{$media_768_991} {
  .flexslider {
    .slides {
      li {
        .content {
          padding-top: 36px;
          .titres {
            padding: 10px;
            p {
              margin-bottom: 15px;
              margin-top: 15px;
              font-size: $p_titre_headder_petit;
            }
            .btn-slide {
              font-family: $p_family_1_2;
              font-size: $p_titre_lien_3;
            }
          }
        }
      }
    }
    .logo {
      width: 400px;
      .imagelogo {
        width: 100%;
      }
      .imagecarre {
        display: none;
      }
      .texte-headder {
        font-size: $p_titre_headder-petit;
        padding-top: 20px;
      }
    }
  }
  .flexactus {
    .flex-viewport {
      height: auto;
    }
  }
}

@media #{$media_480_767} {
  .flexslider {
    @include display-flex;
    @include flex-direction-row;
    @include justify-content(center);
    @include align-items(center);
    background-image: url(../images/headder.jpg);
    background-origin: inherit;
    background-position: left top;
    > .imagelogo {
      display: block;
      margin-top: 40px;
    }
    .slides {
      li {
        > img {
          display: none;
        }
      }
    }
    .logo {
      width: 460px;
      .imagelogo {
        width: 100%;
      }
      .imagecarre {
        display: none;
      }
      .texte-headder {
        font-size: $p_titre_headder-petit;
        padding-top: 10px;
      }
    }
    .flex-viewport {
    }
  }
  .flexactus {
    .flex-viewport {
      height: auto;
    }
  }
}

@media #{$media_320_479} {
  .flexslider {
    @include display-flex;
    @include flex-direction-row;
    @include justify-content(center);
    @include align-items(center);
    background-image: url(../images/headder.jpg);
    background-origin: inherit;
    background-position: left top;
    > .imagelogo {
      display: block;
      margin-top: 40px;
    }
    .slides {
      img {
        display: none;
      }
    }
    .logo {
      width: 340px;
      .imagelogo {
        width: 100%;
      }
      .imagecarre {
        display: none;
      }
    }
    .flex-viewport {
    }
  }
}

@media #{$media_0_319} {
  .flexslider {
    .slides {
      img {
        display: none;
      }
    }
    .logo {
      width: 240px;
      .imagelogo {
        width: 100%;
      }
      .imagecarre {
        display: none;
      }
      .texte-headder {
        font-size: $p_titre_headder-petit;
        padding-top: 10px;
      }
    }
  }
}

.button-slider-g {
  width: 48px;
  height: 24px;
  background-image: url(../../images/flech_g.png);
  background-position: left top;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(../../images/flech_g.png);
    background-position: left -24px;
  }
}

.button-slider-d {
  width: 24px;
  height: 24px;
  background-image: url(../../images/flech_d.png);
  background-position: left top;
  &:hover {
    background-image: url(../../images/flech_d.png);
    background-position: left -24px;
  }
}

.custom-navigation {
  text-align: right;
}

.flex-prev {
  text-align: right;
  display: block;
  position: absolute;
  top: 0px;
  left: 78%;
}

.flex-next {
  text-align: right;
  display: block;
  position: absolute;
  top: 0px;
  left: 85%;
}

@media #{$media_992_1199} {
  .flex-prev {
    left: 80%;
  }
  .flex-next {
    left: 90%;
  }
}

@media #{$media_768_991} {
  .flex-prev {
    left: 65%;
  }
  .flex-next {
    left: 80%;
  }
}

@media #{$media_480_767} {
  .flex-prev {
    left: 50%;
  }
  .flex-next {
    left: 70%;
  }
}

@media #{$media_320_479} {
  .flex-prev {
    left: 10px;
  }
  .flex-next {
    left: 50px;
  }
}

@media #{$media_0_319} {
  .flex-prev {
    display: none;
  }
  .flex-next {
    display: none;
  }
}

.texte-headder {
  position: absolute;
  font-family: $p_family_1_2;
  font-size: $p_titre_headder;
  text-transform: uppercase;
  color: $p_white;
  padding-top: 40px;
}

.box-horaires {
  display: none;
  position: relative;
  // margin-top: -40px;
  width: 100%;
  // height: 210px; //	@include gradient-red;
  // background-image: url(../images/fond_base_accueil.png);
  // background-position: center top;
  // background-repeat: no-repeat;
  // background-size: cover;
  z-index: 50;
  background-color: #770426;
  img {
    position: absolute;
    top: -36px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% + 36px);
  }
}

.horaires {
  font-family: $p_family_2_2;
  font-size: 30px;
  // font-size: $p_titre_headder;
  color: $p_white;
  position: relative;
  text-align: center;
  // padding-top: 60px;
  padding: 25px;
  margin: 0;
}

@media #{$media_768_991} {
  .horaires {
    font-size: $p_titre_lien_2;
  }
}

@media #{$media_0_767} {
  .box-horaires {
    height: 150px;
  }
  .horaires {
    font-size: $p_titre_lien_2;
    padding-top: 45px;
  }
}

@media #{$media_0_319} {
  .horaires {
    font-size: $p_titre_lien_3;
    padding-top: 45px;
  }
}

/* =================== Texture et fond =================== */

.rouille {
  //    background-image: $p_texture_rouille;
  @include gradient-red; //    background-repeat: repeat;
  height: 40px;
}

.ardoise {
  //	@include gradient-red;
  background-image: $p_texture_ardoise;
  background-repeat: repeat;
  margin: 30px 0px 30px 0px;
  padding-bottom: 60px;
}

.gradient-red {
  @include gradient-red;
  margin: 30px 0px 30px 0px;
  padding-bottom: 60px;
  padding-top: 60px;
}

.pointsclair {
  background-image: $p_texture_points;
  background-repeat: repeat;
  margin: 90px 0px 10px 0px;
  padding: 30px 0px 80px 0px;
}

/* =================== Blocs pages =================== */

.byresta {
  h1 {
    font-family: $p_family_1_1;
    font-size: $p_titre_h1;
    color: $p_marron;
    margin-bottom: 0px;
  }
  img {
    padding: 30px;
  }
  p {
    font-family: $p_family_2_2;
    font-size: $p_titre_lien_2;
    color: $p_grismoyen;
    text-transform: uppercase;
  }
}

@media #{$media_0_319} {
  .byresta {
    h1 {
      font-size: $p_titre_h3;
    }
  }
}

.beton {
  @include gradient-red; //    background-image: $p_texture_beton;
  //    background-repeat: repeat;
  padding: 70px 0px 70px 0px;
  margin: 0px 0px 50px 0px;
  .box-accueil-ctn {
    padding-bottom: 30px;
    .box-accueil {
      position: relative;
      border: solid 1px $p_white;
      text-align: center;
      background: $p_white;
      height: 235px;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
      @include border-radius(6px);
      .lien {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 100%;
        text-align: center;
        display: block;
        font-family: $p_family_1_2;
        font-size: $p_titre_lien_1;
        color: $p_orange;
        text-transform: uppercase;
        text-decoration: none;
        padding: 20px 0px 20px 0px;
      }
      &:hover {
        background: $p_orange;
        .lien {
          color: $p_white;
        }
      }
      .survol-beton-roll {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        background-image: $p_texture_fonce_survol;
        z-index: 1;
        @include border-radius(6px);
      }
      &:hover {
        .survol-beton-roll {
          display: block;
        }
      }
      .plus {
        margin-top: 70px;
      }
      img {
        margin: auto;
      }
    }
  }
}

@media #{$media_768_991} {
  .beton {
    .box-accueil-ctn {
      padding-bottom: 30px;
      text-align: center;
      .box-accueil {
        height: 280px;
      }
    }
  }
}

@media #{$media_0_319} {
  .beton {
    .box-accueil-ctn {
      padding-bottom: 30px;
      text-align: center;
      .box-accueil {
        height: 220px;
      }
    }
  }
}

.beton-clair {
  @include gradient-red; //    background-image: $p_texture_beton_clair;
  //    background-repeat: repeat;
  padding: 50px 0px 50px 0px;
}

.margin {
  margin: 0px 0px 30px 0px;
}

.titre-galerie {
  font-size: $p_titre_headder;
  color: $p_marron;
  text-transform: uppercase;
  text-align: center;
}

.survol {
  position: relative;
  &:hover,
  a:focus {
    text-decoration: none;
  }
  .survol-fond {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background-image: $p_texture_claire_survol;
    z-index: 1;
    .plus-carte {
      margin-top: 25%;
      margin-left: 45%;
    }
  }
  .box-carte-resa {
    background-color: $p_bleufonce;
    @include border-radius(6px);
    margin: 20px 0px 50px 0px;
    padding: 0px 0px 0px 0px;
    min-height: 270px;
    img {
      margin: auto;
    }
    .content {
      text-align: left;
      margin-left: 0px;
      p {
        color: $p_grisclair;
        padding-left: 0px;
      }
      h2 {
        font-family: $p_family_1_2;
        color: $p_marron;
        font-size: $p_titre_headder;
      }
      .text-blanc {
        color: $p_white;
        padding-left: 0px;
      }
    }
    .lien {
      font-family: $p_family_1_2;
      font-size: $p_titre_lien_2;
      text-transform: uppercase;
      color: $p_white;
      text-align: center;
      border-top: 2px solid $p_white;
      @include gradient-red; //            background-image: $p_texture_rouille;
      padding: 10px 0px 10px 0px;
      margin: 0px 0px 0px 0px;
      @include border-radius-bottom(6px);
      &:hover {
        background-image: none;
        background-color: $p_bleufonce;
        color: $p_orange;
      }
    }
  }
  &:hover {
    .survol-fond {
      display: block;
    }
    .lien {
      background-image: none;
      background-color: $p_bleufonce;
      color: $p_white;
    }
  }
}

.box-engagement {
  border: solid 1px $p_marron; //	@include gradient-red;
  background: $p_texture_beton_clair;
  padding-left: 0px;
  padding-right: 0px;
  @include border-radius(6px);
  .content2 {
    text-align: left;
    padding-left: 30px;
    padding-right: 15px;
    p {
      font-family: $p_family_2_2;
      color: $p_grisfonce;
    }
    h2 {
      font-family: $p_family_1_2;
      color: $p_bleufonce;
      font-size: $p_titre_headder;
      text-transform: uppercase;
    }
  }
}

@media #{$media_480_767} {
  .survol .box-carte-resa .content {
    text-align: center;
  }
  .box-engagement .content2 {
    text-align: center;
  }
}

@media #{$media_320_479} {
  .survol .box-carte-resa .content {
    text-align: center;
  }
  .box-engagement .content2 {
    text-align: center;
  }
}

@media #{$media_0_319} {
  .survol .box-carte-resa .content {
    text-align: center;
    h2 {
      font-size: $p_date_actus;
    }
  }
  .box-engagement .content2 {
    text-align: center;
    h2 {
      font-size: $p_date_actus;
    }
  }
}

.padding {
  padding: 0 0 0 0px;
}

.liste-actus {
  border-style: solid;
  border-width: 10px;
  -moz-border-image: $p_double-filet 10 repeat;
  -webkit-border-image: $p_double-filet 10 repeat;
  -o-border-image: $p_double-filet 10 repeat;
  border-image: $p_double-filet 10 fill repeat;
  background-image: $p_texture_points_clair;
  @include border-radius(7px);
  margin: 15px 0px 15px 0px;
  &:hover {
    background: $p_blancasse;
    color: $p_white;
  }
  .padding-img-actus {
    text-align: center;
    padding: 5px 5px 5px 5px;
  }
  p {
    height: 90px;
    overflow: hidden;
    color: $p_grisfonce;
  }
  .date-actus {
    font-family: $p_family_2_3;
    font-size: $p_date_actus;
    color: $p_marron;
    margin: 10px 0px 10px 0px;
    border-top: $p_marron solid 1px;
    border-bottom: $p_marron solid 1px;
    height: inherit;
  }
  .h2-actus {
    font-family: $p_family_1_2;
    font-size: $p_h2_actus;
    color: $p_marron;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
    height: 55px;
    overflow: hidden;
    margin: 5px 0px 5px 0px;
  }
}

.details-actus {
  border-style: solid;
  border-width: 10px;
  -moz-border-image: url(../images/filet_bloc.png) 10 repeat;
  -webkit-border-image: url(../images/filet_bloc.png) 10 repeat;
  -o-border-image: url(../images/filet_bloc.png) 10 repeat;
  border-image: url(../images/filet_bloc.png) 10 fill repeat;
  margin: 15px 0px 15px 0px;
  background-image: url(../images/texture_ardoise.jpg);
  a {
    color: $p_marron;
  }
  .padding-img-actus {
    padding: 5px 5px 5px 5px;
  }
  .h2-detail-actus {
    font-family: $p_family_1_2;
    font-size: $p_h2_actus;
    color: $p_marron;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
    margin: 5px 0px 5px 0px;
  }
  .date-actus {
    font-family: $p_family_2_3;
    font-size: $p_date_actus;
    color: $p_marron;
    margin: 20px 0px 20px 0px;
    border-top: $p_marron solid 1px;
    border-bottom: $p_marron solid 1px;
  }
  .text-actus {
    color: $p_white;
  }
  .reseau-sociaux {
    img {
      margin: 20px 25px 20px 0px;
    }
  }
}

.presentation {
  border-style: solid;
  border-width: 11px;
  -moz-border-image: url(../images/filet_bloc.png) 10 repeat;
  -webkit-border-image: url(../images/filet_bloc.png) 10 repeat;
  -o-border-image: url(../images/filet_bloc.png) 10 repeat;
  border-image: url(../images/filet_bloc.png) 10 fill repeat;
  margin: 15px 0px 90px 0px;
  text-align: center;
  @include border-radius(8px);
  background-image: url(../images/texture_ardoise.jpg);
  background-repeat: repeat;
  background-clip: inherit;
  a {
    color: $p_marron;
  }
  p {
    color: #ffffff;
  }
  h2 {
    font-family: $p_family_1_2;
    font-size: $p_intertitre_h2;
    color: $p_marron;
    margin-top: 30px;
    line-height: 24px;
  }
}

.titre-filet {
  background-image: url(../images/pix_marron.png);
  background-repeat: repeat-x;
  background-position: 0 50%;
  text-align: center;
  span {
    font-family: $p_family_1_2;
    font-size: $p_titre_h2;
    color: $p_marron;
    text-transform: uppercase;
    display: inline-block;
    background-color: $p_white;
    padding: 0px 50px;
  }
}

@media #{$media_0_767} {
  .titre-filet {
    background-image: none;
    border-bottom: $p_marron solid 1px;
    border-top: $p_marron solid 1px;
    padding: 20px 0px;
    span {
      font-size: $p_titre_h3;
      padding: 0px 0px;
    }
  }
  .h2-actus {
    font-family: $p_family_1_2;
    font-size: $p_h2_actus_resp;
    color: $p_marron;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
    height: 55px;
    overflow: hidden;
    margin: 5px 0px 5px 0px;
  }
}

@media #{$media_0_319} {
  .titre-filet {
    padding: 10px 0px;
    span {
      font-size: $p_titre_h4;
    }
  }
}

.photos {
  position: relative;
  bottom: 0px;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
  .img-thumbnail {
    height: 200px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
}

.presentation-beton {
  border-style: solid;
  border-width: 10px;
  -moz-border-image: url(../images/filet_bloc.png) 10 repeat;
  -webkit-border-image: url(../images/filet_bloc.png) 10 repeat;
  -o-border-image: url(../images/filet_bloc.png) 10 repeat;
  border-image: url(../images/filet_bloc.png) 10 fill repeat;
  @include gradient-red; //    background-image: $p_texture_beton_clair;
  margin: 10px 0px 90px 0px;
  text-align: center;
  a {
    color: $p_marron;
  }
  h2 {
    font-family: $p_family_1_1;
    font-size: $p_intertitre_h2;
    color: $p_marron;
    margin-top: 30px;
  }
}

.box-formule-midi {
  border: solid 1px $p_marron;
  background-color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 1);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  @include border-radius(6px);
  h2 {
    font-size: $p_titre_h4;
    color: $p_bleufonce;
  }
}

.carte {
  padding: 40px 0px 40px 0px;
  h1 {
    font-size: 36px;
    color: $p_marron;
    font-variant-caps: small-caps;
    font-family: $p_family_1_2;
    text-align: left;
    border-bottom: $p_marron solid 1px;
    margin: 0px 0px 20px 0px;
  }
  h2 {
    font-size: $p_intertitre_h2;
    color: $p_marron;
    font-variant-caps: small-caps;
    font-family: $p_family_1_2;
    text-align: left;
    padding: 10px 0;
    width: 70%;
    float: left;
    margin: 0px 0px 0px 0px;
  }
  span.price {
    width: 30%;
    float: left;
    font-family: $p_family_2_3;
    font-size: $p_intertitre_h2;
    color: $p_gristresclair;
    padding: 5px 0;
    text-align: right;
  }
  img {
    margin-bottom: 40px;
    margin-right: 20px;
  }
}

.menu {
  padding: 40px 0px 40px 0px;
  h2 {
    font-size: $p_intertitre_h2;
    color: $p_white;
    font-variant-caps: small-caps;
    font-family: $p_family_1_2;
    text-align: center;
    padding: 10px 0;
    margin: 0px 0px 0px 0px;
  }
  hr {
    border-bottom: 3px solid $p_white;
    width: 30%;
    margin: 30px auto;
  }
}

@media #{$media_0_767} {
  .carte {
    .img {
      margin-bottom: 40px;
    }
  }
}

.separateur {
  background-image: url(../images/pix_marron.png);
  background-repeat: repeat-x;
  background-position: center top;
  height: 5px;
  margin: 15px 0px 15px 0px;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $p_marron;
}

.margin-btn {
  margin: 20px 0px 0px 0px;
}

.puce {
  margin: 10px 0px 20px 0px;
}

/* =================== Elements NEWSLETTER =================== */

.newsletter {
  padding: 100px 0px 100px 0px;
  margin: 20px 0px 0px 0px;
  background-image: $p_newsletter;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  .items-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .tripadvisor-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% * 2 / 12 - 15px);
    }
  }
}

.newsletter-margin100 {
  margin: 100px 0px 0px 0px;
}

.box-newsletter {
  padding: 20px 10px;
  background-color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.7);
  width: calc(100% * 10 / 12 - 15px);
  @include border-radius(6px);
}

.text-newsletter {
  font-family: $p_family_2_2;
  font-size: $p_texte_newsletter;
  line-height: 28px;
  float: left;
}

.form-horizontal .form-group {
  margin-top: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.form-control {
  color: $p_grisclair;
}

.news_btn .ibs-control input {
  font-family: $p_family_2_2;
  font-size: $p_texte_petit;
  background-color: $p_bleufonce;
  border: 1px solid $p_bleufonce;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 42px;
}

.news_btn .ibs-btn2 {
  font-family: $p_family_2_3;
  font-size: $p_texte_petit;
  color: $p_white;
  text-transform: uppercase;
  border: 1px solid $p_bleufonce;
  background: none;
  background-color: $p_orange;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  padding: 10px 0px 10px;
}

.news_btn .ibs-submit {
  padding-left: 0;
}

.news_btn .ibs-input {
  padding-right: 0;
}

@media #{$media_0_991} {
  .newsletter {
    .items-container {
      .tripadvisor-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 30px;
      }
      .box-newsletter {
        width: 100%;
      }
    }
  }
}

@media #{$media_0_500} {
  .newsletter {
    .items-container {
      .tripadvisor-container {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
      }
      .box-newsletter {
        width: 100%;
      }
    }
  }
}

/* =================== ACTUS-ACCUEIL=================== */

.box-actus {
  padding: 6px 6px 6px 6px;
  background-color: $p_white;
  @include border-radius(6px);
  margin: 10px 0px 10px 0px;
  .titre-resum {
    font-family: $p_family_2_3;
    color: $p_black;
    text-transform: uppercase;
    text-align: left;
    margin: 0px 0px;
    height: 50px;
    overflow: hidden;
  }
  .date {
    font-family: $p_family_2_2;
    font-size: 14px;
    color: $p_marron;
    text-align: left;
    margin: 0px 0px;
  }
  .texte {
    font-family: $p_family_2_1;
    font-size: 14px;
    color: $p_grismoyen;
    text-align: left;
    margin: 5px 0px;
    height: 40px;
    overflow: hidden;
  }
  a {
    font-family: $p_family_1_2;
    color: $p_orange;
    text-transform: uppercase;
  }
  &:hover {
    background-color: $p_bleufonce;
    .titre-resum {
      color: $p_gristresclair;
    }
  }
}

.titre-bandeau {
  font-family: $p_family_1_2;
  font-size: $p_titre_actus_accueil;
  color: $p_bleufonce;
  text-transform: uppercase;
  text-align: left;
  padding: 0px 0px 10px 15px;
}

@media #{$media_768_991} {
  .box-actus {
    .img-responsive {
      margin: 0 auto;
    }
    .titre-resum {
      text-align: center;
    }
    .date {
      text-align: center;
    }
    .texte {
      text-align: center;
    }
    a {
      font-family: $p_family_1_1;
      color: $p_orange;
      text-transform: uppercase;
      text-align: center;
      display: block;
    }
    &:hover {
      background-color: $p_bleufonce;
      .titre-resum {
        color: $p_gristresclair;
      }
    }
  }
}

@media #{$media_0_767} {
  .box-actus {
    .img-responsive {
      margin: 0 auto;
    }
    .titre-resum {
      text-align: center;
    }
    .date {
      text-align: center;
    }
    .texte {
      text-align: center;
    }
    a {
      font-family: $p_family_1_1;
      color: $p_orange;
      text-transform: uppercase;
      text-align: center;
      display: block;
    }
    &:hover {
      background-color: $p_bleufonce;
      .titre-resum {
        color: $p_gristresclair;
      }
    }
  }
}

@media #{$media_0_319} {
  .titre-bandeau {
    font-size: $p_titre_actus_accueil_resp;
    padding: 0px 0px 10px 15px;
  }
}

.glyphicon-menu-down {
  display: block;
  top: -5px;
}

/* =================== BOUTON =================== */

.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.btn-default {
  color: $p_white;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  //	@include gradient-red;
  //    background-image: $p_texture_rouille;
  background-color: $p_orange;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  margin-bottom: 10px;
  font-family: $p_family_1_2;
  font-size: $p_titre_lien_2;
  text-transform: uppercase;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-image: none;
    background-color: $p_bleufonce;
    color: $p_orange;
  }
}

.btn-perso {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  margin-bottom: 10px;
  font-family: $p_family_1_2;
  font-size: $p_titre_lien_3;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: $p_bleufonce;
    color: $p_orange;
  }
}

.btn-blanc {
  color: $p_bleufonce;
  background-color: $p_white;
  font-size: $p_titre_lien_3;
  border-color: $p_marron;
  border: $p_marron solid 1px;
  @include border-radius(6px);
}

/* =================== Arrondis =================== */

.arrondis-top {
  /*border:solid 5px #000000;-*/
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomleft: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.arrondis-bottom {
  /*border:solid 5px #000000;-*/
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-bottomright: 6px;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* =================== Footer =================== */

.footer {
  font-family: $p_family_2_2;
  font-size: $p_texte_footer;
  color: $p_white;
  background-color: $p_bleufonce;
  text-align: left;
  padding-top: 45px;
  a {
    text-decoration: none;
    color: $p_white;
  }
  a:hover,
  a.active {
    color: $p_orange;
  }
  h2 {
    font-family: $p_family_2_3;
    font-size: $p_titre_footer;
    color: $p_marron;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0px;
  }
  h3 {
    font-family: $p_family_2_3;
    font-size: $p_titre_footer;
    color: $p_white;
    text-align: center;
  }
  .reseau-sociaux {
    img {
      margin: 0px 0px 20px 0px;
    }
  }
  .logo-footer {
    max-width: 300px;
    width: 100%;
    margin-bottom: 15px;
  }
  .link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15px;
    list-style: inside;
    li {
      margin: 0 7px;
    }
  }
  .reseau-sociaux {
    display: flex;
    justify-content: center;
    a {
      margin: 0 15px;
    }
  }
  .row {
    > div {
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}

.sous-footer {
  font-family: $p_family_2_2;
  font-size: $p_texte_sousfooter;
  color: $p_grisclair;
  background-color: $p_bleuclair;
  text-transform: uppercase;
  text-align: left;
  padding: 15px 0px 15px 0px;
  margin-top: 60px;
  .conception {
    text-align: right;
  }
}

@media #{$media_768_991} {
  .reseau-sociaux {
    text-align: center;
    padding-top: 30px;
  }
  .sous-footer {
    text-align: center;
    .conception {
      text-align: center;
    }
  }
}

@media #{$media_480_767} {
  .footer {
    .row {
      > div {
        margin-bottom: 30px;
      }
    }
  }
  .reseau-sociaux {
    text-align: center;
    padding-top: 30px;
  }
  .sous-footer {
    text-align: center;
    .conception {
      text-align: center;
    }
  }
  .footer .text-center img {
  }
}

@media #{$media_320_479} {
  .footer {
    .row {
      > div {
        margin-bottom: 30px;
      }
    }
  }
  .reseau-sociaux {
    text-align: center;
    padding-top: 30px;
  }
  .sous-footer {
    text-align: center;
    .conception {
      text-align: center;
    }
  }
  .footer .text-center img {
    width: 80%;
    height: auto;
  }
}

@media #{$media_0_319} {
  .footer ul {
    display: none;
  }
  .footer .text-center img {
    width: 80%;
    height: auto;
  }
}

/* =================== PAGINATION =================== */

.pagination {
  width: 100%;
  height: 40px;
  margin-right: 20px;
  background-color: $p_white;
  padding-left: 10px;
  font-family: $p_family_2_2;
  color: $p_grisfonce;
  font-size: $p_titre_lien_3;
  font-weight: normal;
  line-height: 36px;
  margin-bottom: 20px;
  border: $p_marron solid 1px;
  @include border-radius(6px);
  .liens {
    float: right;
    text-align: right;
    a {
      display: block;
      height: 39px;
      color: $p_grismoyen;
      text-align: center;
      text-decoration: none;
      border-left: 1px solid $p_marron;
      padding-left: 10px;
      padding-right: 10px;
      float: left;
      &:hover {
        background-color: $p_bleufonce;
        color: $p_orange;
      }
    }
    span {
      display: block;
      height: 39px;
      color: $p_grismoyen;
      text-align: center;
      text-decoration: none;
      border-left: 1px solid $p_marron;
      padding-left: 10px;
      padding-right: 10px;
      float: left;
      color: #ffffff;
      background-color: $p_bleufonce;
    }
  }
}

/* =================== PAGE CONTACT =================== */

.beton-clair-contact {
  @include gradient-red; //    background-image: $p_texture_beton_clair;
  //    background-repeat: repeat;
  h2 {
    font-family: $p_family_1_2;
    font-size: $p_titre_h2;
    color: $p_marron;
  }
  .frame {
    margin: 30px 0px 30px 0px;
    padding: 0px 0px 0px 0px;
  }
}

.contact {
  background-color: $p_white;
  padding-top: 70px;
  padding-bottom: 10px;
  .adresse {
    p {
      color: $p_marron;
      font-family: $p_family_2_2;
    }
    .horaires {
      display: block;
      font-family: $p_family_2_2;
      font-size: $p_titre_lien_2;
      color: $p_bleufonce;
      text-transform: uppercase;
      padding-top: 0px;
      padding-bottom: 10px;
    }
  }
  .filet-adresse {
    border-bottom: $p_marron solid 1px;
    border-top: $p_marron solid 1px;
    margin: 10px 20px 10px 20px;
    padding: 20px 0px 20px 0px;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 44px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: $p_marron;
  background-color: $p_white;
  background-image: none;
  border: 1px solid $p_marron;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

label {
  color: $p_marron;
}
