/* Generated by Font Squirrel (https://www.fontsquirrel.com) on February 21, 2017 */

/*

@font-face {
    font-family: 'lora-bold';
    src: url('../fonts/lora-bold-webfont.eot');
    src: url('../fonts/lora-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora-bold-webfont.woff2') format('woff2'),
         url('../fonts/lora-bold-webfont.woff') format('woff'),
         url('../fonts/lora-bold-webfont.ttf') format('truetype'),
         url('../fonts/lora-bold-webfont.svg#lorabold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'lora-bolditalic';
    src: url('../fonts/lora-bolditalic-webfont.eot');
    src: url('../fonts/lora-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/lora-bolditalic-webfont.woff') format('woff'),
         url('../fonts/lora-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/lora-bolditalic-webfont.svg#lorabold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'lora-italic';
    src: url('../fonts/lora-italic-webfont.eot');
    src: url('../fonts/lora-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora-italic-webfont.woff2') format('woff2'),
         url('../fonts/lora-italic-webfont.woff') format('woff'),
         url('../fonts/lora-italic-webfont.ttf') format('truetype'),
         url('../fonts/lora-italic-webfont.svg#loraitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'lora-regular';
    src: url('../fonts/lora-regular-webfont.eot');
    src: url('../fonts/lora-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora-regular-webfont.woff2') format('woff2'),
         url('../fonts/lora-regular-webfont.woff') format('woff'),
         url('../fonts/lora-regular-webfont.ttf') format('truetype'),
         url('../fonts/lora-regular-webfont.svg#loraregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensed_bold';
    src: url('../fonts/robotocondensed-bold-webfont.eot');
    src: url('../fonts/robotocondensed-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-bold-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-bold-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-bold-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-bold-webfont.svg#roboto_condensedbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensed_bolditalic';
    src: url('../fonts/robotocondensed-bolditalic-webfont.eot');
    src: url('../fonts/robotocondensed-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-bolditalic-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-bolditalic-webfont.svg#roboto_condensedbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'roboto_condensed-italic';
    src: url('../fonts/robotocondensed-italic-webfont.eot');
    src: url('../fonts/robotocondensed-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-italic-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-italic-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-italic-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-italic-webfont.svg#roboto_condenseditalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'roboto_condensed_light';
    src: url('../fonts/robotocondensed-light-webfont.eot');
    src: url('../fonts/robotocondensed-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-light-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-light-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-light-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-light-webfont.svg#roboto_condensedlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensed_lightitalic';
    src: url('../fonts/robotocondensed-lightitalic-webfont.eot');
    src: url('../fonts/robotocondensed-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-lightitalic-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-lightitalic-webfont.svg#roboto_condensedlight_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'roboto_condensed_regular';
    src: url('../fonts/robotocondensed-regular-webfont.eot');
    src: url('../fonts/robotocondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-regular-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-regular-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-regular-webfont.svg#roboto_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

*/

//NEW FONTS 05/09/2018

//POPPINS

@font-face {
	font-family: 'poppinsblack';
	src: url('../fonts/poppins-black-webfont.eot');
	src: url('../fonts/poppins-black-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-black-webfont.woff2') format('woff2'),
	url('../fonts/poppins-black-webfont.woff') format('woff'),
	url('../fonts/poppins-black-webfont.ttf') format('truetype'),
	url('../fonts/poppins-black-webfont.svg#poppinsblack') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsblack_italic';
	src: url('../fonts/poppinsoppins-blackitalic-webfont.eot');
	src: url('../fonts/poppinsoppins-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-blackitalic-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-blackitalic-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-blackitalic-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-blackitalic-webfont.svg#poppinsblack_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsbold';
	src: url('../fonts/poppinsoppins-bold-webfont.eot');
	src: url('../fonts/poppinsoppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-bold-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-bold-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-bold-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-bold-webfont.svg#poppinsbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsbold_italic';
	src: url('../fonts/poppinsoppins-bolditalic-webfont.eot');
	src: url('../fonts/poppinsoppins-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-bolditalic-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-bolditalic-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-bolditalic-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-bolditalic-webfont.svg#poppinsbold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsextrabold';
	src: url('../fonts/poppinsoppins-extrabold-webfont.eot');
	src: url('../fonts/poppinsoppins-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-extrabold-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-extrabold-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-extrabold-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-extrabold-webfont.svg#poppinsextrabold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsextrabold_italic';
	src: url('../fonts/poppinsoppins-extrabolditalic-webfont.eot');
	src: url('../fonts/poppinsoppins-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-extrabolditalic-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-extrabolditalic-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-extrabolditalic-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-extrabolditalic-webfont.svg#poppinsextrabold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsextralight';
	src: url('../fonts/poppinsoppins-extralight-webfont.eot');
	src: url('../fonts/poppinsoppins-extralight-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-extralight-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-extralight-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-extralight-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-extralight-webfont.svg#poppinsextralight') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsextralight_italic';
	src: url('../fonts/poppinsoppins-extralightitalic-webfont.eot');
	src: url('../fonts/poppinsoppins-extralightitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-extralightitalic-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-extralightitalic-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-extralightitalic-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-extralightitalic-webfont.svg#poppinsextralight_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsitalic';
	src: url('../fonts/poppinsoppins-italic-webfont.eot');
	src: url('../fonts/poppinsoppins-italic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-italic-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-italic-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-italic-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-italic-webfont.svg#poppinsitalic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinslight';
	src: url('../fonts/poppinsoppins-light-webfont.eot');
	src: url('../fonts/poppinsoppins-light-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppinsoppins-light-webfont.woff2') format('woff2'),
	url('../fonts/poppinsoppins-light-webfont.woff') format('woff'),
	url('../fonts/poppinsoppins-light-webfont.ttf') format('truetype'),
	url('../fonts/poppinsoppins-light-webfont.svg#poppinslight') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinslight_italic';
	src: url('../fonts/poppins-lightitalic-webfont.eot');
	src: url('../fonts/poppins-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-lightitalic-webfont.woff2') format('woff2'),
	url('../fonts/poppins-lightitalic-webfont.woff') format('woff'),
	url('../fonts/poppins-lightitalic-webfont.ttf') format('truetype'),
	url('../fonts/poppins-lightitalic-webfont.svg#poppinslight_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsmedium';
	src: url('../fonts/poppins-medium-webfont.eot');
	src: url('../fonts/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
	url('../fonts/poppins-medium-webfont.woff') format('woff'),
	url('../fonts/poppins-medium-webfont.ttf') format('truetype'),
	url('../fonts/poppins-medium-webfont.svg#poppinsmedium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsmedium_italic';
	src: url('../fonts/poppins-mediumitalic-webfont.eot');
	src: url('../fonts/poppins-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-mediumitalic-webfont.woff2') format('woff2'),
	url('../fonts/poppins-mediumitalic-webfont.woff') format('woff'),
	url('../fonts/poppins-mediumitalic-webfont.ttf') format('truetype'),
	url('../fonts/poppins-mediumitalic-webfont.svg#poppinsmedium_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsregular';
	src: url('../fonts/poppins-regular-webfont.eot');
	src: url('../fonts/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
	url('../fonts/poppins-regular-webfont.woff') format('woff'),
	url('../fonts/poppins-regular-webfont.ttf') format('truetype'),
	url('../fonts/poppins-regular-webfont.svg#poppinsregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinssemibold';
	src: url('../fonts/poppins-semibold-webfont.eot');
	src: url('../fonts/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-semibold-webfont.woff2') format('woff2'),
	url('../fonts/poppins-semibold-webfont.woff') format('woff'),
	url('../fonts/poppins-semibold-webfont.ttf') format('truetype'),
	url('../fonts/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinssemibold_italic';
	src: url('../fonts/poppins-semibolditalic-webfont.eot');
	src: url('../fonts/poppins-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-semibolditalic-webfont.woff2') format('woff2'),
	url('../fonts/poppins-semibolditalic-webfont.woff') format('woff'),
	url('../fonts/poppins-semibolditalic-webfont.ttf') format('truetype'),
	url('../fonts/poppins-semibolditalic-webfont.svg#poppinssemibold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsthin';
	src: url('../fonts/poppins-thin-webfont.eot');
	src: url('../fonts/poppins-thin-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-thin-webfont.woff2') format('woff2'),
	url('../fonts/poppins-thin-webfont.woff') format('woff'),
	url('../fonts/poppins-thin-webfont.ttf') format('truetype'),
	url('../fonts/poppins-thin-webfont.svg#poppinsthin') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppinsthin_italic';
	src: url('../fonts/poppins-thinitalic-webfont.eot');
	src: url('../fonts/poppins-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/poppins-thinitalic-webfont.woff2') format('woff2'),
	url('../fonts/poppins-thinitalic-webfont.woff') format('woff'),
	url('../fonts/poppins-thinitalic-webfont.ttf') format('truetype'),
	url('../fonts/poppins-thinitalic-webfont.svg#poppinsthin_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

//abeatbykai

@font-face {
    font-family: 'abeatbykairegular';
    src: url('../fonts/abeatbykairegular-webfont.eot');
    src: url('../fonts/abeatbykairegular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/abeatbykairegular-webfont.woff2') format('woff2'),
         url('../fonts/abeatbykairegular-webfont.woff') format('woff'),
         url('../fonts/abeatbykairegular-webfont.ttf') format('truetype'),
         url('../fonts/abeatbykairegular-webfont.svg#abeatbykairegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//moanhand

@font-face {
    font-family: 'moanhandregular';
    src: url('../fonts/moanhand-webfont.eot');
    src: url('../fonts/moanhand-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/moanhand-webfont.woff2') format('woff2'),
         url('../fonts/moanhand-webfont.woff') format('woff'),
         url('../fonts/moanhand-webfont.ttf') format('truetype'),
         url('../fonts/moanhand-webfont.svg#moanhandregular') format('svg');
    font-weight: normal;
    font-style: normal;
}